<template>
    <div>
        <!--面包屑-->
        <div class="page-breadcrumb">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
                <el-breadcrumb-item>{{ page_name }}</el-breadcrumb-item>
            </el-breadcrumb>
        </div>

        <!--内容-->
        <div class="page-content-x">
            <!--标题-->
            <el-row>
                <el-col :span="12"><h3>平台数据</h3></el-col>
            </el-row>
            <!--搜索-->
            <div class="page-content-search">
                <el-form ref="form" size="medium" :label-width="this.env.search_label_width">
                    <el-row>
                        <el-col :span="12">
                            <el-form-item label="时间">
                                <el-select v-model="searchtype" style="width: 30%">
                                    <el-option label="月统计" value="month"></el-option>
                                    <el-option label="周统计" value="week"></el-option>
                                    <el-option label="自定义" value="custom"></el-option>
                                </el-select>
                                <el-date-picker
                                        style="width: 70%"
                                        v-show="searchtype == 'month'"
                                        v-model="month"
                                        type="month"
                                        placeholder="选择月"
                                        value-format="yyyy-MM"
                                >
                                </el-date-picker>
                                <el-date-picker
                                        style="width: 70%"
                                        v-show="searchtype == 'week'"
                                        v-model="selectWeek"
                                        type="week"
                                        format="yyyy 第 WW 周"
                                        value-format="yyyy-MM-dd"
                                        :picker-options="pickerOptions"
                                        placeholder="选择周"
                                        @change="searchWeekTime">

                                </el-date-picker>
                                <el-date-picker
                                        style="width: 70%"
                                        v-show="searchtype == 'custom'"
                                        v-model="time"
                                        type="daterange"
                                        range-separator="至"
                                        start-placeholder="开始日期"
                                        end-placeholder="结束日期"
                                        format="yyyy 年 MM 月 dd 日"
                                        value-format="yyyy-MM-dd">
                                </el-date-picker>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row class="page-content-search-button">
                        <el-col :span="24">
                            <el-form-item>
                                <el-button icon="el-icon-search" size="medium" type="primary" @click="is_search">搜索
                                </el-button>
                                <!--                                <el-button icon="el-icon-search" size="medium" type="primary" @click="to_chartdata">查看图表
                                                                </el-button>-->
                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-form>
            </div>

            <div v-loading="loading">
                <!--收入-->
                <h3>微信支付</h3>
                <el-row :gutter="16">
                    <el-col :span="6" v-if="is_auth('reportforms.platformdata.wxpay_bill')">
                        <el-card shadow="hover">
                            <div class="d-flex" @click="page_display">
                                <i class="icon fa fa-jpy default"></i>
                                <div>
                                    <div>{{ info.wxpay_bill }}</div>
                                    <el-tooltip class="item" effect="dark" content="所有微信支付的金额（包含退款）"
                                                placement="bottom-start">
                                        <div>总支付流水</div>
                                    </el-tooltip>
                                </div>
                            </div>
                        </el-card>
                    </el-col>
                    <el-col :span="6" v-if="is_auth('reportforms.platformdata.wxpay_bill_lesson')">
                        <el-card shadow="hover">
                            <div class="d-flex" @click="page_display">
                                <i class="icon fa fa-jpy default"></i>
                                <div>
                                    <div>{{ info.wxpay_bill_lesson }}</div>
                                    <el-tooltip class="item" effect="dark" content="使用微信支付的课程金额（不包含退款）"
                                                placement="bottom-start">
                                        <div>课程支付流水</div>
                                    </el-tooltip>
                                </div>
                            </div>
                        </el-card>
                    </el-col>
                    <el-col :span="6" v-if="is_auth('reportforms.platformdata.wxpay_bill_coupon_bale')">
                        <el-card shadow="hover">
                            <div class="d-flex" @click="page_display">
                                <i class="icon fa fa-jpy default"></i>
                                <div>
                                    <div>{{ info.wxpay_bill_coupon_bale }}</div>
                                    <el-tooltip class="item" effect="dark" content="使用微信支付购买的券包金额（不包含退款）"
                                                placement="bottom-start">
                                        <div>券包支付流水</div>
                                    </el-tooltip>
                                </div>
                            </div>
                        </el-card>
                    </el-col>
                    <el-col :span="6" v-if="is_auth('reportforms.platformdata.wxpay_bill_refund')">
                        <el-card shadow="hover">
                            <div class="d-flex" @click="page_display">
                                <i class="icon fa fa-jpy default"></i>
                                <div>
                                    <div>{{ info.wxpay_bill_refund }}</div>
                                    <el-tooltip class="item" effect="dark" content="使用微信支付退款的金额"
                                                placement="bottom-start">
                                        <div>取消退款</div>
                                    </el-tooltip>
                                </div>
                            </div>
                        </el-card>
                    </el-col>
                </el-row>
                <div style="height: 20px"></div>

                <h3>数据</h3>
                <el-row :gutter="16">
                    <el-col :span="6" v-if="is_auth('reportforms.platformdata.total_money')">
                        <el-card shadow="hover">
                            <div class="d-flex" @click="page_display">
                                <i class="icon fa fa-jpy blue"></i>
                                <div>
                                    <div>{{ info.total_money }}</div>
                                    <el-tooltip class="item" effect="dark" content="已结束服务的收入总额（团课+训练营+私教）"
                                                placement="bottom-start">
                                        <div>服务收入总额</div>
                                    </el-tooltip>
                                </div>
                            </div>
                        </el-card>
                    </el-col>
                    <el-col :span="6" v-if="is_auth('reportforms.platformdata.coupon_total_money')">
                        <el-card shadow="hover">
                            <div class="d-flex" @click="page_display">
                                <i class="icon fa fa-ticket blue"></i>
                                <div>
                                    <div>{{ info.coupon_total_money }}</div>
                                    <el-tooltip class="item" effect="dark" content="使用优惠券减掉的总额（团课+训练营）"
                                                placement="bottom-start">
                                        <div>优惠券总额</div>
                                    </el-tooltip>
                                </div>
                            </div>
                        </el-card>
                    </el-col>
                    <el-col :span="6" v-if="is_auth('reportforms.platformdata.balance_total_money')">
                        <el-card shadow="hover">
                            <div class="d-flex" @click="page_display">
                                <i class="icon fa fa-credit-card-alt blue"></i>
                                <div>
                                    <div>{{ info.balance_total_money }}</div>
                                    <el-tooltip class="item" effect="dark" content="使用余额支付课程的总额（团课+训练营+私教）"
                                                placement="bottom-start">
                                        <div>余额支付</div>
                                    </el-tooltip>
                                </div>
                            </div>
                        </el-card>
                    </el-col>
                    <el-col :span="6" v-if="is_auth('reportforms.platformdata.wx_total_money')">
                        <el-card shadow="hover">
                            <div class="d-flex" @click="page_display">
                                <i class="icon fa fa-weixin blue"></i>
                                <div>
                                    <div>{{ info.wx_total_money }}</div>
                                    <el-tooltip class="item" effect="dark" content="使用微信支付课程的总额（团课+训练营+私教）"
                                                placement="bottom-start">
                                        <div>微信支付</div>
                                    </el-tooltip>
                                </div>
                            </div>
                        </el-card>
                    </el-col>
                </el-row>
                <div style="height: 20px"></div>
                <el-row :gutter="16">
                    <el-col :span="6" v-if="is_auth('reportforms.platformdata.newuser_register_num')">
                        <el-card shadow="hover">
                            <div class="d-flex" @click="page_display">
                                <i class="icon fa fa-user-plus yellow"></i>
                                <div>
                                    <div>{{ info.newuser_register_num }}</div>
                                    <el-tooltip class="item" effect="dark" content="统计时段内新注册的人数"
                                                placement="bottom-start">
                                        <div>新增注册人数</div>
                                    </el-tooltip>
                                </div>
                            </div>
                        </el-card>
                    </el-col>
                    <el-col :span="6" v-if="is_auth('reportforms.platformdata.newuser_consumption_num')">
                        <el-card shadow="hover">
                            <div class="d-flex" @click="page_display">
                                <i class="icon fa fa-user yellow"></i>
                                <div>
                                    <div>{{ info.newuser_consumption_num }}</div>
                                    <el-tooltip class="item" effect="dark" content="统计时段内新注册并且有过1次已消费（上课）的人数"
                                                placement="bottom-start">
                                        <div>新增消费人数</div>
                                    </el-tooltip>
                                </div>
                            </div>
                        </el-card>
                    </el-col>
                    <el-col :span="6" v-if="is_auth('reportforms.platformdata.newuser_conversion_rate')">
                        <el-card shadow="hover">
                            <div class="d-flex" @click="page_display">
                                <i class="icon fa fa-pie-chart yellow"></i>
                                <div>
                                    <div>{{ info.newuser_conversion_rate }}%（{{
                                            info.newuser_consumption_num
                                        }}/{{ info.newuser_register_num }}）
                                    </div>
                                    <el-tooltip class="item" effect="dark" content="新增消费人数/新增注册人数"
                                                placement="bottom-start">
                                        <div>转化率</div>
                                    </el-tooltip>
                                </div>
                            </div>
                        </el-card>
                    </el-col>
                    <el-col :span="6" v-if="is_auth('reportforms.platformdata.newuser_repurchase_rate')">
                        <el-card shadow="hover">
                            <div class="d-flex" @click="page_display">
                                <i class="icon fa fa-pie-chart yellow"></i>
                                <div>
                                    <div>{{ info.newuser_repurchase_rate }}%（{{
                                            info.newuser_repurchase_num
                                        }}/{{ info.newuser_consumption_num }}）
                                    </div>
                                    <el-tooltip class="item" effect="dark" content="统计时段内新用户复购（2次已上消费）人数/统计时段内新用户消费人数"
                                                placement="bottom-start">
                                        <div>新用户复购比例</div>
                                    </el-tooltip>
                                </div>
                            </div>
                        </el-card>
                    </el-col>
                </el-row>
                <div style="height: 20px"></div>
                <el-row :gutter="16">
                    <el-col :span="6" v-if="is_auth('reportforms.platformdata.newuser_fission_num')">
                        <el-card shadow="hover">
                            <div class="d-flex" @click="page_display">
                                <i class="icon fa fa-user-plus yellow"></i>
                                <div>
                                    <div>{{ info.newuser_fission_num }}</div>
                                    <el-tooltip class="item" effect="dark" content="统计时段内通过别人推荐过来完成注册的人数"
                                                placement="bottom-start">
                                        <div>裂变注册人数</div>
                                    </el-tooltip>
                                </div>
                            </div>
                        </el-card>
                    </el-col>
                    <el-col :span="6" v-if="is_auth('reportforms.platformdata.newuser_fission_consumption_num')">
                        <el-card shadow="hover">
                            <div class="d-flex" @click="page_display">
                                <i class="icon fa fa-user yellow"></i>
                                <div>
                                    <div>{{ info.newuser_fission_consumption_num }}</div>
                                    <el-tooltip class="item" effect="dark" content="统计时段内通过别人推荐过来的并且有过1次已消费（上课）的人数"
                                                placement="bottom-start">
                                        <div>裂变消费人数</div>
                                    </el-tooltip>
                                </div>
                            </div>
                        </el-card>
                    </el-col>
                    <el-col :span="6" v-if="is_auth('reportforms.platformdata.repurchase_num')">
                        <el-card shadow="hover">
                            <div class="d-flex">
                                <i class="icon fa fa-user yellow"></i>
                                <div>
                                    <div>{{ info.repurchase_num }}</div>
                                    <el-tooltip class="item" effect="dark" content="统计时间内购买2次以上，并完成服务的人数"
                                                placement="bottom-start">
                                        <div>活跃人数</div>
                                    </el-tooltip>
                                </div>
                            </div>
                        </el-card>
                    </el-col>
                    <el-col :span="6" v-if="is_auth('reportforms.platformdata.repurchase_rate')">
                        <el-card shadow="hover">
                            <div class="d-flex" @click="page_display">
                                <i class="icon fa fa-pie-chart yellow"></i>
                                <div>
                                    <div>{{ info.repurchase_rate }}%（{{ info.repurchase_num }}/{{
                                            info.consumption_num
                                        }}）
                                    </div>
                                    <el-tooltip class="item" effect="dark" content="统计时段内2次以上消费/截止到这个时段总消费人数"
                                                placement="bottom-start">
                                        <div>复购率</div>
                                    </el-tooltip>

                                </div>
                            </div>
                        </el-card>
                    </el-col>
                </el-row>
                <div style="height: 20px"></div>
                <el-row :gutter="16">
                    <el-col :span="6" v-if="is_auth('reportforms.platformdata.coach_active_num')">
                        <el-card shadow="hover">
                            <div class="d-flex">
                                <i class="icon el-icon-s-custom red"></i>
                                <div>
                                    <div>{{ info.coach_active_num }}</div>
                                    <el-tooltip class="item" effect="dark" content="统计时段内属于练市集公司的教练有排课记录的教练人数"
                                                placement="bottom-start">
                                        <div>共享门店活跃教练</div>
                                    </el-tooltip>
                                </div>
                            </div>
                        </el-card>
                    </el-col>
                    <el-col :span="6" v-if="is_auth('reportforms.platformdata.store_active_num')">
                        <el-card shadow="hover">
                            <div class="d-flex">
                                <i class="icon el-icon-s-home red"></i>
                                <div>
                                    <div>{{ info.store_active_num }}</div>
                                    <el-tooltip class="item" effect="dark" content="统计时段内有排课记录的门店的数量"
                                                placement="bottom-start">
                                        <div>活跃门店</div>
                                    </el-tooltip>
                                </div>
                            </div>
                        </el-card>
                    </el-col>
                </el-row>
                <div style="height: 20px"></div>
            </div>

        </div>
    </div>
</template>
<script>

export default {
    components: {},
    data() {
        return {
            searchtype: 'month',
            page_name: '报表管理',
            month: '',           // 月份
            selectWeek: '',
            // 日期选择 配置
            pickerOptions: {
                firstDayOfWeek: 1,      // 从周一开始
            },
            weekArrangeData: [],        // 周数据

            time: null,
            info: {},

            settagPage: false,

        }
    },
    // 创建
    created() {
        this.getdatatime()
        this.is_search()
    },
    // 安装
    mounted() {

    },
    methods: {
        // 显示图表，暂无用
        page_display() {
            // this.settagPage = true    // 显示页面
        },
        getdatatime() {
            let date = new Date();
            let y = date.getFullYear();
            let M = date.getMonth() + 1;
            let theDay = `${y.toString()}-${M.toString()}`;
            this.time = theDay;
        },
        // 选择周时间
        searchWeekTime() {
            console.log('选择周时间')
            let selectWeek = this.selectWeek        // 选择的周数据

            let DateArea = this.getDateArea(selectWeek)
            this.weekArrangeData = [DateArea[0]['date'], DateArea[6]['date']]
            // this.weekArrangeData.push(DateArea[0]['date'], DateArea[6]['date'])
        },
        // 获取选中的一周日期数据
        getDateArea(currentTime) {
            let currentDate = new Date(currentTime)
            let timesStamp = currentDate.getTime()
            let currenDay = currentDate.getDay()
            let dates = []
            for (let i = 0; i < 7; i++) {
                // let date = new Date(timesStamp + 24 * 60 * 60 * 1000 * (i - (currenDay + 6) % 7)).toLocaleDateString().replace(/\//g, '-')
                let date = new Date(timesStamp + 24 * 60 * 60 * 1000 * (i - (currenDay + 6) % 7)).toISOString().split('T')[0]
                dates.push({
                    date,
                });
            }
            return dates
        },
        // 权限判断
        is_auth(auth) {
            return this.Tool.is_auth(auth)
        },
        //搜索
        is_search() {
            this.getinfo()
        },
        //查看图表-暂时无用
        to_chartdata() {
            this.$router.push({path: '/reportforms/platformdata/chartdata'})
        },
        // 获取数据
        getinfo() {
            let date = [''];
            if (this.searchtype == 'month') {
                if (!this.Tool.is_empty(this.month)) {
                    date = [this.month + '-01']
                }
            } else if (this.searchtype == 'week') {
                date = this.weekArrangeData
            } else if (this.searchtype == 'custom') {
                date = this.time
            }

            let postdata = {
                api_name: 'reportforms.platformdata.getinfo',
                token: this.Tool.get_l_cache('token'),
                searchtype: this.searchtype,
                date,
            }
            // 判断时间条件
            this.loading = true
            this.Tool.post_data('oss', postdata, (json) => {
                this.loading = false
                if (json.code === 0) {
                    this.info = json.data.info
                } else {
                    this.Tool.errormes(json)
                }
            })
        },
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.d-flex {
    display: flex;
    align-items: center;
    justify-items: center;
}

.default {
    color: #409EFF;
}

.red {
    color: #fc142f;
}

.yellow {
    color: #F4BA28;
}

.blue {
    color: #637FFB;
}

.icon {
    font-size: 60px;
    margin-right: 20px;
}
</style>
